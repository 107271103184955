.point_amount {
    background-color: var(--color-blue);
    width: 85px;
    height: 18px;
    border-radius: 3px;
    text-align: center;
}

.point_amount_p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 18px;
    color: var(--text-color-white);
}