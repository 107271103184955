/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 18px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-red);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .checkbox:checked + .slider {
    background-color: var(--color-green);
  }
  
  .checkbox:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .checkbox {
    position: relative;
  }
  
  .check_symbol {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    fill: var(--color-white);
  }
  
  .attention {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    fill: var(--color-white);
  }
  