.footer {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 0;
}

.text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    color: #858598;
    padding: 0 10px;
    border-left: 1px solid #858598;
    border-right: 1px solid #858598;
    margin-left: 10px;
    margin-right: 10px;
}

.date {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    color: #858598;
}


@media (max-width: 1100px) {
    .footer {
        z-index: 11;
        right: -25px;
    }
}

@media (max-width: 768px) {
    .footer {
        width: 100%;
        bottom: 260px;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .footer {
        display: none;
    }
}