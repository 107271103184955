@media (min-width: 768px) {
    .wrapper {
        margin: 0 auto;
        margin-bottom: 30px;
        top: 115px;
        font-size: 1.125rem;
        letter-spacing: 0.08em;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.04em;
        color: #000000;
    }

    .week {
        padding: 2px 0 0 5px;
        font-size: 0.85rem;
        font-weight: bold;
        vertical-align: middle;
        bottom: 2px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.2em;
        color: #000000;
        margin-left: 10px;
    }
}

@media (min-width: 1280px) {
    .wrapper {
        margin: 0;
        margin-bottom: 0;
    }
}