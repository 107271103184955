.card_footer {
    width: 280px;
    height: 68px;
    background-color: var(--color-orange);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: relative;
}