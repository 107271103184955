.wrapper {
  margin: 0 auto;
  padding: 20px 0 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.125rem;
  letter-spacing: 0.08em;
}

.week {
  font-size: 0.85rem;
  font-weight: bold;
  vertical-align: middle;
  bottom: 2px;
}
