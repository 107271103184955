.link {
    text-decoration: none;
    display: flex;
}

.bigLogoText {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-right: 7.5px;
}

.smallLogoText {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    color: #858598;
    margin: 0;
    padding: 0;
    margin-right: 5.5px;
}