.MobilePointsTaskContainer {
    margin: 0 auto;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #f1f1f1;
    padding: 15px 0;
}

.mobileplanningContainer {
    max-width: 100%;
    min-height: 100vh;
    background-color: var(--bg-color-gray);
}

.planningContainer {
    max-width: 100%;
    background-color: var(--bg-color-gray);
}

.list {
    padding-bottom: 40px;
}

@media (min-width: 768px) {

    .pointsTaskContainer {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
        position: relative;
    }

    .list {
        padding-bottom: 0;
    }
}

@media (min-width: 1280px) {
    .planningContainer {
        min-height: calc(100vh - 70px);
    }

    .pointsTaskContainer {
        margin: 0 auto;
        flex-direction: inherit;
        justify-content: space-between;
        width: 1186px;
        padding-bottom: 0;
        position: relative;
    }

    .secondTaskContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 784px;
        position: relative;
    }

    .list {
        padding-top: 40px;
        height: calc(100vh - 230px);
        overflow: auto;
    }
}