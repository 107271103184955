.wrapper {
    margin: 0 auto;
    letter-spacing: 0.16em;
    margin-top: 10px;
    margin-bottom: 10px;
}

.task {
    display: flex;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 21px;
    color: #858598;
}

.day {
    display: flex;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
}

@media (min-width: 768px) {
    .wrapper {
        display: flex;
        justify-content: center;
    }

    .task {
        margin-right: 5px;
    }
}

@media (min-width: 1280px) {
    .wrapper {
        width: 400px;
        justify-content: start;
        font-size: 12px;
        padding: 20px 0;
        margin: 0;
    }
}