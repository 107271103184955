.wrapper_awards {
  background-color: var(--bg-color-gray);
  position: relative;
}

.present_items {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 25px;
  align-items: center;
  margin-bottom: 20px;
}

.present_cards {
  min-height: calc(100vh - 70px);
  margin: 0 auto;
}

.present_item {
  display: flex;
  justify-content: space-between;
}

.card_list_wrapper {
  max-height: calc(100vh - 300px);
  overflow: auto;
  padding-bottom: 30px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .present_items {
    flex-direction: row;
    justify-content: space-between;
    max-width: 585px;
  }

  .card_list_wrapper {
    max-height: calc(100vh - 300px);
    margin-bottom: 15px;
  }
}

@media (min-width: 1280px) {
  .present_items {
    min-width: 1184px;
    padding-top: 0;
  }

  .card_list_wrapper {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 300px);
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .present_cards {
    max-height: 100vh;
    /* margin: 0 auto; */
  }
}
