.btn {
    background: #FFBC33;
    border-radius: 6px;
    width: 49%;
    padding: 15px 0;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #FAFAFA; 
    border: 0;
    text-align: center;
    box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.1);
}

.btn:hover {
    background: #d89715;
    cursor: pointer;
}