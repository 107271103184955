.navigationList {
  display: block;
  padding: 5px 5px;
  display: flex;
  max-width: 420px;
  justify-content: space-between;
  list-style: none;
}

.navigationItem {
  position: relative;
  color: #858598;
  text-transform: uppercase;
}

.navigationItem:not(:last-child) {
  margin-right: 20px;
}

.navigationItem:not(:last-child)::after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 2px;
  height: 100%;
  background: grey;
  top: 0px;
  right: -12px;
}

.navigationLink {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #858598;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.activeNavigationLink {
  color: #000000;
}

@media (max-width: 860px) {
  .navigationList {
      display: none;
  }
}