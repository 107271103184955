.container {
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.userDataContainer {
    display: flex;
    align-items: center;
}

.userInfoLogo {
    width: 26px;
    height: 24px;
    border-radius: 15px;
    margin-right: 15px;
    object-fit: contain;
}

.userInfoName {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #858598;
    padding-right: 15px;
    border-right: 1px solid #858598;
    margin-right: 15px;
}

.logoutIcon {
    cursor: pointer;
}

@media (max-width: 860px) {
    .container {
        display: none;
    }
}