.addTaskBtn {
    display: block;
    position: absolute;
    top: 0;
    left: 77%;
    transform: translateY(-50%);
    width: 54px;
    height: 54px;
    background-color: #eeb844;
    box-shadow: 0px 8px 29px 0px rgba(178, 138, 61, 0.31);
    border-style: none;
    border-radius: 50%;
    font-size: 43px;
    font-weight: 500;
    color: #fff;
}