@import './libs/normalize.css';
@import './fonts.css';
@import './vars.css';

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

​img {
  display: block;
  max-width: 100%;
  height: auto;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}
