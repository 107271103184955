.taskModal {
    width: 300px;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.taskImage {
    height: 130px;
    background-color: #fff;
    position: relative;
}

.taskCloseButton {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 15px;
    border: none;
    background-color: transparent;
}

.taskForm {
    background-color: #ffbc33;
    position: relative;
}

.form {
    /* padding-bottom: 25px; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.taskInput {
    font-size: 16px;
    color: #ffffff;
    background-color: transparent;
    padding: 7px;
    border: none;
    border-bottom: 1px solid #fff;
    text-align: center;
    width: 224px;
    font-style: italic;
    margin-bottom: 30px;
}

::placeholder {
    color: #ffffff;
}

.taskPoints {
    font-size: 16px;
    color: #ffffff;
    background-color: transparent;
    appearance: none;
    border: none;
    border-bottom: 1px solid #fff;
    padding: 7px;
    text-align: center;
    width: 224px;
    font-style: italic;
    margin-bottom: 20px;
}

.taskIconEdit {
    position: absolute;
    top: 2px;
    left: 10px;
    filter: invert(1);
}

.gradeIconEdit {
    position: absolute;
    top: 2px;
    left: 10px;
    filter: invert(1);
}

.taskSubmitButton {
    display: block;
    padding: 10px 20px;
    margin: 25px auto 35px;
    border-radius: 5px;
    border-style: none;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000000;
}

.taskSubmitButton:hover {
    background-color: rgb(211, 209, 209);
}

.form label .error::first-letter {
    text-transform: uppercase;
}

.textSection {
    position: relative;
}

.pointsSection {
    position: relative;
}

/* ============== */
.pointError {
    position: absolute;
    bottom: -15px;
    left: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    color: red;
}

.titleError {
    position: absolute;
    bottom: 8px;
    left: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    color: red;
}

.labelFileInput {
    width: 90%;
    margin: 35px auto 0;
    font-family: "Montserrat", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

@media (max-width: 500px) {
    .taskImage {
        display: none;
    }

    .labelFileInput {
        margin: 15px auto 0;
    }

    .taskSubmitButton {
        display: block;
        padding: 10px 20px;
        margin: 15px auto 15px;
    }

    .pointError {
        bottom: -5px;
    }
}