.headerContainer {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    position: relative;
}

.headerContent {
    width: 90%;
    margin: 0 auto;
    padding: 21px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container {
    display: flex;
}

.burgerMenu {
    display: none;
}

@media (max-width: 860px) {
    .burgerMenu {
        display: block;
        border: 0;
        background: transparent;
    }
}
