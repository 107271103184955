.content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    position: relative;
}

.leftSideContainer {
    width: 50%;
    min-height: calc(100vh - 70px);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftSideContent {
    width: 610px;
    height: 740px;
    position: relative;
}

.home1Image {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 1;
}

.joystickImage {
    position: absolute;
    top: 190px;
    left: 220px;
    z-index: 2;
}

.robotImage {
    position: absolute;
    top: 350px;
    left: 0;
    z-index: 3;
}

.home2Image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 4;
}

.rightSide {
    width: 50%;
    min-height: calc(100vh - 70px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text {
    width: 401px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    color: #000000;
    margin-bottom: 30px;
}

.tabletImg1 {
    display: none;
}

.tabletImg2 {
    display: none;
}

.tabletImg3 {
    display: none;
}

.mobileImg1 {
    display: none;
}

@media (max-width: 1100px) {

    .rightSide {
        position: relative;
        z-index: 10;
    }
}

@media (max-width: 768px) {
    .content {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .rightSide {
        width: 100%;
        min-height: auto;
        padding: 75px 0;
    }

    .leftSideContainer {
        width: 100%;
        min-height: auto;
    }

    .leftSideContent {
        width: 100%;
        height: 240px;
    }

    .home1Image {
        display: none;
    }
    
    .joystickImage {
        display: none;
    }
    
    .robotImage {
        display: none;
    }
    
    .home2Image {
        display: none;
    }

    .main {
        position: relative;
    }

    .tabletImg1 {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 301px;
        height: 150px;
        z-index: 1;
        background-image:url('../../assets/images/authPage/home1.png');
        background-position: 25% 10%;
        /* background-size:cover; */
    }
    
    .tabletImg2 {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        width: 213px;
        height: 170px;
        z-index: 3;
        background-image:url('../../assets/images/authPage/joystick.png');
        background-position:center center;
        background-size:cover;
    }
    
    .tabletImg3 {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 315px;
        height: 200px;
        z-index: 2;
        background-image:url('../../assets/images/authPage/home2.png');
        background-position: 75% 25%;
        /* background-size:cover; */
    }
}

@media (max-width: 480px) {

    .leftSideContent {
        width: 100%;
        height: 150px;
    }

    .tabletImg1 {
        display: none;
    }
    
    .tabletImg2 {
        display: none;
    }
    
    .tabletImg3 {
        display: none;
    }
    
    .mobileImg1 {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        width: 100%;
        height: 130px;
        z-index: 3;
        background-image:url('../../assets/images/authPage/mobileJoystick.png');
        background-position: center center;
        background-size: cover;
        overflow: hidden;
    }

    .rightSide {
        padding: 25px 0;
    }

    .text {
        width: 100%;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    }
}