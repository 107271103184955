.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userInfoLogo {
    width: 26px;
    height: 24px;
    border-radius: 15px;
    margin-bottom: 15px;
    object-fit: contain;
}

.userInfoName {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #fff;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
    margin-bottom: 15px;
}

.logoutIcon {
    cursor: pointer;
    fill: #fff;
}