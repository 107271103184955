.authFormContainer {
    width: 400px;
    background: #FFFFFF;
    box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 40px 20px;
}

.googleLink {
    width: 150px;
    background: #F6F7FB;
    border-radius: 6px;
    box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.1);
    padding: 11px 32px;
    margin-bottom: 30px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.googleLink:hover {
    box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.2);
}

.linkText {
    margin-left: 10px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #000000;
}

.btn_container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #858598;
    margin-bottom: 20px;
}

.error {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
    color: #EB5757;
}

@media (max-width: 480px) {
    .authFormContainer {
        width: 100%;
        background: #FFFFFF;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
    }
}