.container {
    background-color: var(--bg-color-gray);
    width: 100%;
    padding: 20px;
    padding-top: 10px;
}

@media (min-width: 768px) {
    .container {
        padding-top: 0px;
    }
}

@media (min-width: 1280px) {
    .container {
        padding: 0 50px;
        width: 1280px;
    }

    .progressBarAndCurrentDayWrapper {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .cardWrapper {
        /* height: calc(100vh - 250px); */
        height: calc(100vh - 265px);
        padding-top: 20px;
        overflow: auto;
    }

    .cardWrapper>ul {
        max-width: 890px;
        margin: 0 auto;
    }
}