.mainDiv {
    min-height: calc(100vh - 70px);
    background-color: var(--bg-color-gray);
}

@media (min-width: 768px) {
    .mainDiv {
        min-height: calc(100vh - 35px);
    }
}

@media (min-width: 1280px) {
    .mainDiv {
        min-height: calc(100vh - 70px);
        display: flex;
    }
}