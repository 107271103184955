.week {
    display: flex;
    justify-content: center;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    padding-bottom: 26px;
}

@media (max-width: 1280px) and (min-width: 760px) {
    .week {
        padding: 24px 45px 24px 95px;
    }
}

@media (min-width: 1280px) {
    .week {
        font-size: 18px;
        justify-content: start;
        padding: 24px 0 0;
    }
}