.wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 280px;
    padding: 10px 0;
    margin: 0 auto;
}

.info {
    width: 100%;
}

.progressTitle {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 1.2px;
    color: #111111;
    margin-bottom: 15px;
}

.pointsTitle {
    margin-left: 5px;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 1.2px;
    color: #000000;
}

.progressText {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.04em;
    color: #A6ABB9;
    margin-bottom: 5px;
}

.points {
    margin-left: 5px;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.2em;
    color: #1d1d1d;
}

.progressLibrary_container {
    display: flex;
    justify-content: flex-end;
}

.progressLibrary_container>div {
    width: 200px;
}

.secondPoints {
    font-weight: bold;
    margin-right: 5px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.2em;
    color: #1d1d1d;
}

/* .secondPointsFirstNumber {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    letter-spacing: 0.2em;
    color: #000000;
} */

@media (min-width: 1280px) {
    .wrapper {
        margin: 0;
        width: 350px;
    }

    .progressLibrary_container>div {
        width: 280px;
    }
}