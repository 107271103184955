.content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 300px;
    max-height: 75vh;
    background-color: #fff;
    padding: 60px 0 20px;
}

.taskCloseButton {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 15px;
    border: none;
    background-color: transparent;
}

.catTop {
    position: absolute;
    top: -70px;
}

.imagePrize {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 7px solid #ffbd33;
    margin: 0 auto;
}

.modalTitleText {
    padding-top: 25px;
    text-align: center;
    padding-bottom: 30px;
}

.prizeContainerList {
    max-height: 70vh;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    overflow: auto;
}

.prizeContainerItem {
    width: 120px;
}

.prizeContainerItemText {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: var(--color-blue);
    font-size: 18px;
    font-weight: 700;
}

@media (min-width: 768px) {
    .content {
        width: 650px;
        padding: 60px 20px 60px;
    }

    .prizeContainerItem {
        width: 150px;
    }
}