.weekDays {
    background-color: var(--color-orange);
    width: 25%;
    position: relative;
}

.weekDaysList {
    list-style: none;
    width: 206px;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.weekDaysListLink {
    display: block;
    background-color: var(--color-light-orange);
    width: 100%;
    margin-bottom: 4px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 15px 0 15px 36px;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #000000;
}

.weekDaysListItem:last-of-type {
    margin-bottom: 0;
}

.active {
    background-color: var(--bg-color-white);
}

/* WeekTabsTablet shows with css rule on interval 1280px to infiniti */
@media (max-width: 1279px) {
    .weekDays {
        display: none;
    }
}