.footer {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    color: #858598;
    padding: 0 10px;
    border-left: 1px solid #858598;
    border-right: 1px solid #858598;
    margin-left: 10px;
    margin-right: 10px;
}

.date {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    color: #858598;
}

/* @media (max-width: 1100px) {
    .footer {
        z-index: 11;
        right: -25px;
    }
} */

@media (max-width: 1279px) {
    .footer {
        position: static;
        width: 100%;
        justify-content: center;
        padding-bottom: 30px;
        background-color: var(--bg-color-gray);
    }
}

@media (max-width: 480px) {
    .footer {
        flex-direction: column;
        padding-bottom: 65px;
    }

    .text {
        padding: 0;
        border-left: 0;
        border-right: 0;
        margin: 10px 0;
    }
}