.select_days {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    border: none;

    position: relative;
}

.addPlusButton {
    fill: var(--color-green);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    position: absolute;
}

.select_days_span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: var(--color-green);
}