.DayList {
    padding: 8px 0px 8px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-blue);
    color: var(--color-white);
    max-width: 150px;
    font-size: 14px;
    border-radius: 5px;
    list-style: none;
}

.form {
    position: absolute;
    bottom: 74px;
    right: -10px;
}

/* The container */
.container_checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 6px;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 80px;
}

/* Hide the browser's default checkbox */
.input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--color-blue);
    border: 2px solid var(--color-white);
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container_checkbox:hover .input~.checkmark {
    background-color: var(--color-white);
}

/* When the checkbox is checked, add a blue background */
.container_checkbox .input:checked~.checkmark {
    background-color: var(--color-white);
}

/* Create the checkmark/indicator (hidden when not checked) */
.container_checkbox .checkmark_icon {
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container_checkbox .input:checked~.checkmark .checkmark_icon {
    display: block;
}

/* Style the checkmark/indicator */
.container_checkbox .checkmark_icon {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: var(--color-blue);
}



.container_checkbox_disable {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 6px;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 80px;
    color: rgb(184, 179, 179);
}
/* On mouse-over, add a grey background color */
.container_checkbox_disable .input~.checkmark {
    border: 2px solid rgb(184, 179, 179);
}
/* When the checkbox is checked, add a blue background */
.container_checkbox_disable .input:checked~.checkmark {
    background-color: var(--color-white);
}
/* Create the checkmark/indicator (hidden when not checked) */
.container_checkbox_disable .checkmark_icon {
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.container_checkbox_disable .input:checked~.checkmark .checkmark_icon {
    display: block;
}
/* Style the checkmark/indicator */
.container_checkbox_disable .checkmark_icon {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: var(--color-blue);
}
