.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1051;
    background: rgba(0, 0, 0, 0.3);  
    display: flex;
    justify-content: center;
    align-items: center;
}
