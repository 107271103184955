.weekDays {
    background-color: var(--color-orange);
    display: flex;
    margin-bottom: 20px;
}

.weekDaysList {
    display: flex;
    list-style: none;
    justify-content: space-between;
    width: 350px;
    margin: 0 auto;
    font-weight: bold;
    padding: 24px 0;
    margin-left: 0;
}

.weekDaysListLink {
    display: block;
    background-color: var(--color-light-orange);
    width: 100%;
    margin-right: 15px;
    text-align: center;
    border-radius: 5px;
    font-size: 11px;
    line-height: 21px;
    text-decoration: none;
    color: var(--text-color-black);
}

.weekDaysListItem:last-of-type {
    margin-right: 0;
}

.active {
    background-color: var(--bg-color-white);
}

/* WeekTabsTablet shows with css rule on interval 768px to 1279px: */

@media (max-width: 767px) {
    .weekDays {
        display: none;
    }
}

@media (min-width: 1280px) {
    .weekDays {
        display: none;
    }
}