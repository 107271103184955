.modalContent {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
}

.modalTitleText {
  text-align: center;
  margin-bottom: 40px;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 350px;
}

.btn {
  width: 45%;
  border: none;
  border-radius: 6px;
  background-color: #FFBC33;
  padding: 15px 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.02em;
  color: #FAFAFA; 
  border: 0;
  text-align: center;
  box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.1);
}

.btn:hover {
    background: #d89715;
    cursor: pointer;
}

@media (max-width: 768px) {
  .modalContent {
    width: 280px;
  }

  .buttonContainer {
    width: 280px;
  }
}
