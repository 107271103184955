.pointsText {
    text-align: center;
    text-transform: none;
    font-size: 11px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #A6ABB9;
    position: relative;
    top: 2px;
}

.pointsAmount {
    display: inline-block;
    vertical-align: middle;
    padding-top: 7px;
    text-align: center;
    width: 30px;
    height: 30px;
    margin-right: 6px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #8ec63f;
    border-radius: 50%;
    vertical-align: baseline;
}

.hiddenSpan {
    display: inline-block;
    margin-right: 6px;
    color: #A6ABB9;
}