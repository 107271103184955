.customWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.hiddenText {
    margin-right: 25px;
    display: inline-block;
    font-size: 11px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.04em;
    color: #A6ABB9;
}

.addTaskBtn {
    display: block;
    width: 54px;
    height: 54px;
    background-color: #eeb844;
    box-shadow: 0px 8px 29px 0px rgba(178, 138, 61, 0.31);
    border-style: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconPlus {
    font-size: 43px;
    line-height: 54px;
    font-weight: 500;
    color: #fff;
    position: relative;
    top: -1px;
}