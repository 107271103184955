.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  max-width: 200px;
  height: 100vh;
  background-color: #eeb844;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigationList {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.close {
  border: none;
  background: none;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  opacity: 0.5;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 10px;
  top: 0;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.navigationItem {
  border-bottom: 1px solid white;
  width: 100%;
}
.navigationLink {
  font-family: "Monserrat", sans-serif;
  text-decoration: none;
  font-size: 12px;
  padding: 6px 0;
  letter-spacing: 0.3px;
  font-weight: 500;
  color: #fff;
  display: block;
  width: 100%;
  padding: 10px;
}
.activeNavigationLink {
  color: #000;
}
